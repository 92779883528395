<template>
  <div class="donateRes">
    <div class="donateRes-t">
      <div class="img"><img src="./img/ok.png" alt="" /></div>
      <div class="txt">
        <span v-if="isDonate == 1">捐款发起</span
        ><span v-else>受捐请求发起</span>
      </div>
    </div>
    <div class="donateRes-b">
      <div class="btn-t" @click="toReturn">返回</div>
      <div class="btn-b" @click="toLook">去查看</div>
    </div>
  </div>
</template>

<script>
import { toRegister, gloabalCount } from "@/utils/common.js";
import {} from "./api.js";
var moment = require("moment");
export default {
  name: "donateRes",
  data() {
    return {
      isDonate: 1,
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.isDonate = this.$route.query.isDonate;
  },
  mounted() {},
  methods: {
    toLook() {
      this.$router.push({
        name: "loveDonateList",
        query: {
          isDonate: this.isDonate,
        },
      });
    },
    toReturn() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.donateRes {
  min-height: 100vh;
  padding-top: 200px;
  box-sizing: border-box;
  .donateRes-t {
    .img {
      width: 252px;
      height: 152px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .txt {
      font-weight: 700;
      font-size: 32px;
      color: #333333;
      line-height: 44px;
      text-align: center;
      margin-top: 34px;
    }
  }
  .donateRes-b {
    .btn-t {
      width: 550px;
      height: 70px;
      border-radius: 16px;
      border: 2px solid #3781ff;
      text-align: center;
      font-weight: 600;
      font-size: 30px;
      color: #3781ff;
      line-height: 70px;
      margin: 500px auto 0;
    }
    .btn-b {
      width: 550px;
      height: 70px;
      background: #3781ff;
      border-radius: 16px;
      text-align: center;
      font-weight: 700;
      font-size: 30px;
      color: #ffffff;
      line-height: 70px;
      margin: 40px auto;
    }
  }
}
</style>
